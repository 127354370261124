import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import CardComponent from "./card";
import {
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Typography,
} from "@material-ui/core";
import data from "./HomePage";
import { Box } from "grommet";
import fire from "../firebase";

export class HomePage extends Component {
  state = {
    Coaster: [],
    Plaque: [],
    CustomInspired: [],
    CustomSign: [],
    Magnet: [],
    Frame: [],
    AboutUs: [],
  };

  componentDidMount() {
    fire
      .storage()
      .ref("FirstPagePictures")
      .listAll()
      .then((mainFolder) =>
        mainFolder.prefixes.map((folder) =>
          folder.listAll().then((files) => {
            files.items.forEach((imgRef) =>
              imgRef.getDownloadURL().then((url) => {
                folder.name === "Coaster"
                  ? this.setState((prevState) => ({
                      Coaster: [url],
                    }))
                  : folder.name === "Customer inspired gifts"
                  ? this.setState((prevState) => ({
                      CustomInspired: [url],
                    }))
                  : folder.name === "Custom Signs"
                  ? this.setState((prevState) => ({
                      CustomSign: [url],
                    }))
                  : folder.name === "Frame"
                  ? this.setState((prevState) => ({
                      Frame: [url],
                    }))
                  : folder.name === "Magnet"
                  ? this.setState((prevState) => ({
                      Magnet: [url],
                    }))
                  : folder.name === "Plaque"
                  ? this.setState((prevState) => ({
                      Plaque: [url],
                    }))
                  : this.setState((prevState) => ({
                      AboutUs: [url],
                    }));
              })
            );
          })
        )
      )
      .catch((err) => err.message);
    
  }
  render() {
    return (
      <Box
        height={{ vertical: "large" }}
        background={{
          image:
            "url(https://firebasestorage.googleapis.com/v0/b/engraved-933fe.appspot.com/o/Background%20image%2FFree_Wood_Texture_10.jpg?alt=media&token=15b8cdb8-3339-4a02-b764-a7edcb1cfbe7)",
        }}
        pad={{ vertical: "large" }}
      >
        <div style={{ margin: "2%" }}>
          <Grid container xs={12} justify="center" spacing={4}>
            <Grid  xs={4}  item >
              <Link to="Gallery" style={{ textDecoration: "none" }}>
                <CardComponent
                  title="Custom Signs"
                  image={this.state.CustomSign[0]}
                  alt="Custom Signs"
                />
              </Link>
            </Grid>
            <Grid  xs={4} item >
              <Link to="Gallery" style={{ textDecoration: "none" }}>
                <CardComponent
                  title="Customer inspired Gifts"
                  image={this.state.CustomInspired[0]}
                  alt="Customer inspired Gifts"
                />
              </Link>
            </Grid>
          </Grid>
          <Grid container xs={12} justify="center" spacing={4}>
            <Grid xs={4} item>
              <Link to="Gallery" style={{ textDecoration: "none" }}>
                <CardComponent
                  title="Magnets"
                  image={this.state.Magnet[0]}
                  alt="Magnets"
                />
              </Link>
            </Grid>
            <Grid xs={4} item >
              <Link to="Gallery" style={{ textDecoration: "none" }}>
                <CardComponent
                  title="Frames"
                  image={this.state.Frame[0]}
                  alt="Frames"
                />
              </Link>
            </Grid>
          </Grid>
          <Grid container xs={12} justify="center" spacing={4}>
            <Grid xs={4} item >
              <Link to="Gallery" style={{ textDecoration: "none" }}>
                <CardComponent
                  title="Plaques & Awards"
                  image={this.state.Plaque[0]}
                  alt="Plaques"
                />
              </Link>
            </Grid>
            <Grid xs={4} item  >
              <Link to="Gallery" style={{ textDecoration: "none" }}>
                <CardComponent
                  title="Coasters"
                  image={this.state.Coaster[0]}
                  alt="Coasters"
                />
              </Link>
            </Grid>
          </Grid>
          <Grid container xs={12} justify="center" spacing={4}>
            <Grid xs={4} item >
              <Link to="/How-Its-Done" style={{ textDecoration: "none" }}>
                <Card
                  style={{ margin: "2%", height: "200" }}
                  key="How Its Work"
                >
                  <CardActionArea>
                    <CardMedia
                      height="200"
                      component="img"
                      image={
                        "https://firebasestorage.googleapis.com/v0/b/engraved-933fe.appspot.com/o/Background%20image%2FFree_Wood_Texture_10.jpg?alt=media&token=15b8cdb8-3339-4a02-b764-a7edcb1cfbe7"
                      }
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        How Its Done
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        engraved
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
            <Grid xs={4} item >
              <Link to="/About Us" style={{ textDecoration: "none" }} data= {this.state.AboutUs[0]} >
                <Card style={{ margin: "2%", height: "200" }} key="About Us">
                  <CardActionArea>
                    <CardMedia
                      height="200"
                      component="img"
                      image={
                        "https://firebasestorage.googleapis.com/v0/b/engraved-933fe.appspot.com/o/Background%20image%2FFree_Wood_Texture_10.jpg?alt=media&token=15b8cdb8-3339-4a02-b764-a7edcb1cfbe7"
                      }
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        About Us
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        engraved
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </div>
      </Box>
    );
  }
}

export default HomePage;
