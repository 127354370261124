import React from "react";
import "./Footer.css";

import { Footer, grommet, Grommet, Text } from "grommet";

export default function FooterSection() {

  return (
    <div>
      <Grommet theme={grommet}>
        <Footer background="black" pad="small" justify="center">
          <Text textAlign="center" size="medium" alignSelf="center">
          engraved.sd@gmail.com
          </Text>
        </Footer>
      </Grommet>
    </div>
  );
}
